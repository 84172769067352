<template>
  <div class="olimp-admin-form">
    <a-form-model
      class="mb-1"
      ref="accountForm"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="name" prop="name" label="Пользователь">
            <a-input v-model="form.name"
                     placeholder="Имя пользователя, например User3"
            >
              <a-icon slot="prefix" type="user" class="text-primary" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="email" prop="email" label="Email">
            <a-input v-model="form.email"
                     placeholder="Email"
            >
              <a-icon slot="prefix" type="mail" class="text-primary" />
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="password" prop="password" label="Пароль" >
            <a-input-password v-model="form.password"
                              placeholder="Укажите пароль"
                              type="password"
            >
              <a-icon slot="prefix" type="unlock" class="text-primary" />
            </a-input-password>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="confirm" prop="confirm" label="Повтор пароля">
            <a-input-password v-model="form.confirm"
                              placeholder="Укажите пароль повторно"
                              type="password"
            >
              <a-icon slot="prefix" type="unlock" class="text-primary" />
            </a-input-password>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <div v-if="checkAdminRole(user.roles)" class="ml-2">
            <p class="font-size-14 mb-0">Выберите роли:</p>
            <a-form-model-item prop="roles" style="clear:both;">
              <a-checkbox-group v-model="form.roles"  name="roles" @change="handleRolesChange">
                <span>
                  <a-checkbox v-for="role in allRoles" :key="role.name" :value="role.name" name="roles" :disabled="role.name === 'admin'"  @change="handleRolesChange">
                    {{ role.name }}
                  </a-checkbox>
                </span>
              </a-checkbox-group>
            </a-form-model-item>
          </div>
        </a-col>
      </a-row>
      <hr>
      <div class="d-flex">
        <div class="mr-auto">
          <!-- <a-button style="margin-left: 1rem;" @click="closeModal">
            Cancel
          </a-button> -->
          <a-button style="margin-left: 1rem;" @click="closeModal">
            Отмена
          </a-button>
        </div>
        <div>
          <a-button type="primary" @click="handleSubmit" :loading="processing">
            Создать
          </a-button>
        </div>
      </div>
<!--      <div class="text-right">-->
<!--        -->
<!--        -->
<!--      </div>-->
    </a-form-model>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import store from 'store'
import accessMix from '@/services/accessmix'

export default {
  name: 'createModal',
  props: ['userInfo'],
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    loading() {
      return this.processing
    },
    allRoles() {
      return this.roles_list.map(({ id, name }) => ({ id, name }))
    },
  },
  data() {
    const validateNewPass = (rule, value, callback) => {
      if (value !== '') {
        if (this.form.confirm !== '') {
          this.$refs.accountForm.validateField('confirm')
        }
        callback()
      } else {
        callback(new Error('Password is required'))
      }
    }
    const validateConfirm = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('Two passwords are different!'))
      } else {
        callback()
      }
    }
    return {
      processing: false,
      roles_list: store.get('app.users.roles'),
      form: {
        name: '',
        email: '',
        password: '',
        confirm: '',
        roles: [],
      },
      rules: {
        name: [{ required: true, message: 'Please input account name', trigger: 'change' }],
        email: [{ required: true, message: 'Please input email', trigger: 'change' }],
        password: [
          { required: true, validator: validateNewPass, trigger: 'change' },
        ],
        confirm: [
          { validator: validateConfirm, trigger: 'change' },
        ],
        roles: [
          { required: true, message: 'Please select at least one role', trigger: 'change' },
        ],
      },
    }
  },
  mounted () {
    // ---
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.accountForm.validate(valid => {
        if (valid) {
          this.processing = true
          this.createAccount()
        } else {
          console.log('not valid')
          return false
        }
      })
    },
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(obj) {
      this.$emit('createAccount', obj)
    },
    handleRolesChange() {
      // ---
      // console.log('roles changed', this.form.roles)
    },
    createAccount() {
      const url = '/admin/user'
      console.log(this.form)
      return apiClient.post(url, this.form).then((response) => {
        console.log(response)
        this.$notification.success({
          message: 'User account successfully created!',
          description: '',
        })
        this.processing = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while creating user account',
          description: error.message,
        })
        this.processing = false
      })
    },
  },
}
</script>

<style scoped>

</style>
