<template>
  <div class="d-flex flex-wrap flex-column align-items-center">
    <a-avatar shape="square" :size="64" icon="user" :src="userInfo.avatar" />
    <div class="text-center mt-2">
      <div class="text-dark font-weight-bold font-size-18">{{ userInfo.name }}</div>
      <div class="font-size-14 mb-3">{{ userInfo.email }}</div>
      <div class="text-uppercase font-size-12 mb-3"><a-tag>{{ userInfo.roles[0].name }}</a-tag></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserCard',
  props: ['userInfo'],
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
