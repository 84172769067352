<template>
  <div class="users-content">
    <a-row>
      <a-col :span="18">
        <h3>Пользователи</h3>
      </a-col>
      <a-col :span="6" class="text-right">
        <a-button type="primary" size="large" @click="createAccountModal">
          <a-icon type="plus" /> Добавить
        </a-button>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-row>
        <a-col :span="6"
           v-for="(usr, index) in users"
           :key="usr.id"
        >
          <a-card
            class="m-2"
          >
            <div class="card-body">
              <user-card :user-info="usr" />
              <div v-if="checkPermissions(user.permissions, accessModule, 'edit')" class="text-center">
                <div>
                  <div class="p-1 d-inline-block">
                    <a-button type="button" class="btn btn-primary" @click="showProfileModal(usr, index)">
                      <a-icon type="edit" /> Редактировать
                    </a-button>
                  </div>
                  <div class="p-1 d-inline-block">
                    <a-popconfirm
                      title="Удалить пользователя?"
                      @confirm="onDelete(usr.id)"
                    >
                      <a-button type="button" class="btn btn-outline-danger">
                        <a-icon type="delete" /> Удалить
                      </a-button>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-card>
    <a-modal
      v-model="profileModal"
      title="User Profile Info"
      @ok="handleSubmitProfile"
      :footer="null"
      class="profile-modal"
      width="600px"
      :destroyOnClose="true"
    >
      <user-profile
        :user-info="selUser"
        :user-index="selIndex"
        @delete="onDelete"
        @closeModalFunction="handleCloseProfile"
        @submitModalFunction="handleSubmitProfile"
      />
    </a-modal>
    <a-modal
      v-model="accountModal"
      title="Создать нового пользователя"
      :footer="null"
      class="account-modal"
      width="720px"
      :destroyOnClose="true"
    >
      <create-modal
        @createAccount="handleCreateAccount"
        @closeModalFunction="handleCreateCancel"
        ref="createAccountModal"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import userCard from '@/components/kit/widgets/General/usercard'
import userProfile from '@/views/apps/users/profile.vue'
import createModal from './createModal.vue'

export default {
  name: 'index',
  components: {
    userCard, userProfile, createModal,
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    users() {
      return this.usersObj
    },
  },
  data() {
    return {
      accessModule: 'users',
      usersObj: undefined,
      selUser: undefined,
      selIndex: 0,
      profileModal: false,
      accountModal: false,
      accountObj: undefined,
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    showProfileModal(user, index) {
      this.selUser = user
      this.selIndex = index
      this.profileModal = true
    },
    createAccountModal() {
      this.accountModal = true
    },
    handleCreateAccount(obj) {
      // ---
      // console.log('account created', obj)
      this.usersObj.push(obj)
      this.accountModal = false
    },
    handleCreateCancel() {
      this.accountModal = false
    },
    handleSubmitProfile(e) {
      this.usersObj[this.selIndex] = e
      // console.log(e)
      this.profileModal = false
    },
    handleCloseProfile(e) {
      this.profileModal = false
    },
    getUsers() {
      const url = '/admin/user/all'
      apiClient.get(url).then((response) => {
        this.usersObj = response.data.data
      }).catch(error => { console.log(error) })
    },
    onDelete(key) {
      const target = this.usersObj.filter(item => key === item.id)[0]
      const url = '/admin/user/' + key
      apiClient.delete(url).then((response) => {
        this.$notification.success({
          message: 'User "' + target.name + '" successfully deleted',
          description: '',
        })
        this.usersObj.splice(this.usersObj.findIndex(x => x.id === target.id), 1)
        // this.appData = dataSource.filter(item => item.application_id !== key)
        // this.cacheData = this.appData.map(item => ({ ...item }))
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting user account',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
